html {
    background-color: black !important;
}

.viewport-fix {
    min-height: 100vh; /* fall-back */
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
    background-color: black;
}
