

.fullScreen {
  overflow: scroll;
    &-wrapper {
      position: relative;
      &--active {
        background: #fafafa;
        padding: 1rem;
      }
    }

  &-disActiveButton {
    position: absolute;
    top: 20px;
    left: 1%;
  }
}

