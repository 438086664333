@media print {
    body {
        color-adjust: exact;
    }
}

.print {
    background-color: white;

    .header {
        border: solid 2px #d2601a;
        width: 100%;
        text-align: center;
        font-size: 25px;
        padding-bottom: 10px;

        .title {
            background-color: #d2601a;
            color: white;
            width: 100%;
            font-size: 27px;
            text-align: left;
            padding: 5px 10px;
            margin-bottom: 10px;
        }
    }

    .questions {
        list-style-type: none;
        margin-left: 0;
        margin-top: 20px;
        padding-left: 0;
        // max-width: 600px;

        li {
            display: flex;

            .question {
                display: flex;
                font-size: 20px;
                padding: 3px 10px;

                &.strip {
                    background-color: rgb(236, 234, 234);
                }

                .question-text {
                    width: 600px;
                }
            }

            .answer-space {
                border-bottom: dotted 1px black;
                width: 250px;
                margin-left: 10px;
            }
        }
    }

    .signature {
        margin-top: 30px;
        border: solid 1px lightgray;
        border-collapse: collapse;

        td {
            border: solid 1px lightgray;
            font-weight: bold;
            padding: 5px;
            font-size: 17px;

            &.second {
                width: 150px;
            }
        }
    }
}
